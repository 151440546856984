<template>
  <div class="wujie-select-channel">
    <!-- 文档资料库 -->
    <VhallDialog
      title="选择渠道"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :z-index="zIndex ? zIndex : null"
      width="600px"
    >
      <div class="channel-list">
        <div v-if="isSearch || total">
          <vh-input
            class="head-btn search-tag"
            placeholder="请输入渠道名称/渠道参数"
            v-clearEmoij
            v-model="formParams.keyword"
            @keyup.enter.native="searchHandle(false)"
            clearable
            @clear="searchHandle(false)"
          >
            <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchHandle(false)"></i>
          </vh-input>
          <!-- 表格 -->
          <vh-table
            ref="elTable"
            :data="dialogTableList"
            tooltip-effect="dark"
            style="width: 100%"
            :row-class-name="rowClassName"
            :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
            @selection-change="changeDialogCheck"
            @select-all="checkAllRow"
            max-height="360px"
          >
            <vh-table-column type="selection" width="55" align="left" />
            <vh-table-column label="名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <p class="text">
                  {{ scope.row.channel_name }}
                </p>
              </template>
            </vh-table-column>
            <vh-table-column
              label="参数"
              prop="channel_code"
              :show-overflow-tooltip="true"
            ></vh-table-column>
            <div slot="empty">
              <NullPage :nullType="'search'" v-if="!total" :height="60"></NullPage>
            </div>
          </vh-table>
        </div>
        <template v-else>
          <div class="no-live" v-show="!total && !(isSearch && total == 0)">
            <NullPage
              :nullType="'nullData'"
              :text="`您还没有渠道，快来创建吧！<p>请在「运营设置-渠道设置」中提前创建</p>`"
              :height="10"
            ></NullPage>
          </div>
        </template>

        <div class="btn-center" v-if="isSearch || total">
          <span class="select-option">
            当前选中
            <b>{{ currentSelect.length }}</b>
            个渠道
          </span>
          <vh-button type="info" plain round size="medium" @click="cancelCheckHandle">
            取消
          </vh-button>
          <vh-button
            v-preventReClick
            type="primary"
            round
            size="medium"
            @click="saveCheckHandle"
            :disabled="!(currentSelect && currentSelect.length > 0)"
          >
            确定
          </vh-button>
        </div>
      </div>
    </VhallDialog>
  </div>
</template>

<script>
  import NullPage from '@/views/PlatformModule/Error/nullPage.vue';
  export default {
    name: 'wujieSelectChannel',
    components: {
      NullPage
    },
    props: {
      // 选中的数据
      checkedList: {
        required: false,
        type: Array,
        default: () => []
      },
      source_type: {
        type: Number,
        default: 1
      },
      scene_type: {
        type: Number,
        default: 1
      },
      source_id: {
        type: String,
        default: ''
      },
      zIndex: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        dialogVisible: false,
        total: 0,
        isSearch: false,
        nullText: 'search',
        isCheckAll: false,
        dialogTableList: [],
        formParams: {
          keyword: ''
        },
        totalPages: 0,
        currentSelect: [],
        mainObj: null, //主渠道
        allList: [], //初始的所有数据
        diffList: [] //搜索之后的list和所有数据的差异数据
      };
    },
    watch: {
      currentSelect(newVal, oldVal) {
        this.$nextTick(() => {
          this.readonlyTable();
        });
      }
    },
    methods: {
      rowClassName({ row, rowIndex }) {
        let classname = '';
        if (row.channel_type == 0) {
          classname = 'disabledClass';
        }
        return classname;
      },
      handleClose(done) {
        done();
      },
      // 获取资料库-弹出框内容
      getDialogTableList(init = false) {
        let params = {
          keyword: this.formParams.keyword,
          source_type: this.source_type || 1,
          source_id: this.source_id || this.$route.params.str,
          scene_type: this.scene_type
        };
        if (this.scene_type === 1) {
          delete params.scene_type;
        }
        this.isSearch = this.formParams.keyword ? true : false;
        this.$fetch('getChannelListSelected', this.$params(params), {
          'Content-Type': 'application/json'
        })
          .then(res => {
            if (res && res.code === 200) {
              this.dialogTableList = res.data.list;
              this.total = res.data.total;

              if (init) {
                this.mainObj = res.data.list[0];
                this.currentSelect = JSON.parse(JSON.stringify(res.data.selected_ids));
                console.log(this.currentSelect);
                this.allList = res.data.list;
              } else {
                if (this.dialogTableList.length != 0 && this.dialogTableList[0].channel_type != 0) {
                  this.dialogTableList.unshift(this.mainObj);
                  this.total++;
                }
                this.currentSelect.unshift(this.mainObj.id);
              }
              this.diffList = this.allList.filter(e => {
                return !this.dialogTableList.some(e2 => e2.id == e.id);
              });
              this.diffList = this.diffList.filter(e => {
                return this.currentSelect.some(e2 => e2 == e.id);
              });
              // if (this.isCheckAll) {
              //   this.$refs.elTable.toggleAllSelection();
              // }
            }
          })
          .catch(e => {
            console.log(e);
          });
        // .finally(() => {
        //   this.readonlyTable();
        // });
      },
      searchHandle(init = false) {
        // this.currentSelect = [];
        // try {
        //   this.$refs.elTable.clearSelection();
        // } catch (e) {
        //   console.log(e);
        // }
        this.getDialogTableList(init);
      },
      checkAllRow(selection) {
        console.log('全选与非全选', selection);
        // 只要数量大于0，即是够了全选
        this.isCheckAll = selection && selection.length > 0;
      },
      // 改变资料库-弹出框内容
      changeDialogCheck(val) {
        console.log('单选', val);
        this.$nextTick(() => {
          this.currentSelect = this.diffList
            .map(item => item.id)
            .concat(
              [this.mainObj.id],
              val.map(item => item.id)
            );
          this.currentSelect = [...new Set(this.currentSelect)];
        });

        // this.readonlyTable();
      },
      initComp() {
        // 历史已经选择过的数据清空
        // this.currentSelect = JSON.parse(JSON.stringify(this.checkedList));
        this.dialogVisible = true;
        this.isCheckAll = false;
        this.formParams.keyword = '';
        this.currentSelect = [];
        this.searchHandle(true);
      },
      readonlyTable() {
        if (this.dialogTableList.length > 0 && this.currentSelect.length > 0) {
          for (let i = 0; i < this.dialogTableList.length; i++) {
            if (this.currentSelect.includes(this.dialogTableList[i].id)) {
              this.$nextTick(() => {
                this.$refs.elTable.toggleRowSelection(this.dialogTableList[i], true);
              });
            }
          }
        }
      },
      saveCheckHandle() {
        let res = this.allList.filter(item => {
          return this.currentSelect.some(i => {
            return item.id == i;
          });
        });
        console.log(res);
        this.$emit('selected', res);
        this.cancelCheckHandle();
      },
      cancelCheckHandle() {
        this.currentSelect = [];
        try {
          this.$refs.elTable && this.$refs.elTable.clearSelection();
        } catch (e) {
          console.log(e);
        }
        this.dialogVisible = false;
      }
    },
    mounted() {}
  };
</script>

<style lang="less" scoped>
  .channel-list {
    // padding-bottom: 12px;
    .head-btn {
      margin-bottom: 16px;
    }
    .vh-input {
      width: 220px;
      .vh-input__icon {
        cursor: pointer;
      }
      /deep/ .vh-input__icon {
        line-height: 36px;
      }
    }
    /deep/ .vh-input__inner {
      user-select: none;
      border-radius: 50px;
      font-size: 14px;
      color: #666666;
      height: 36px;
      line-height: 36px;
    }
    .search-tag {
      /deep/.vh-input__inner {
        border-radius: 20px;
        height: 36px;
        padding-right: 30px !important;
      }
      /deep/ .vh-input__suffix {
        cursor: pointer;
      }
    }
  }
  .btn-center {
    margin: 0 auto;
    text-align: right;
    .vh-button {
      margin-right: 2px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  /deep/.select-option {
    float: left;
    line-height: 20px;
    margin-top: 8px;
    b {
      color: #fb3a32;
    }
  }
  .channel-list {
    /deep/ .vh-table__body .vh-table__row td:nth-child(2) .cell {
      padding-left: 10px;
    }
    /deep/ thead tr th:nth-child(2) .cell {
      padding-left: 10px;
    }
    /deep/.cell .imgs {
      width: 100px;
      height: 100px;
    }
    /deep/.cell .advImg {
      width: 142px;
      height: 80px;
    }
    /deep/.vh-table {
      margin-bottom: 30px;
    }
    /* /deep/.vh-table__header{
    background-color: #FB3A32;
  } */
    /deep/.vh-table td,
    .vh-table th {
      padding: 15px 0;
    }
    .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /deep/.vh-button.vh-button--text {
      color: #1a1a1a;
      border: 0;
      &:hover {
        color: #fb3a32;
      }
    }
    .empty {
      text-align: center;
    }
  }
</style>
<style lang="less">
  .wujie-select-channel {
    .vh-radio-group {
      width: 100%;
      max-width: 100%;
    }
    .vh-radio__label {
      display: none;
    }
    .vh-table td:first-child,
    .vh-table th:first-child {
      padding-left: 24px;
    }
    .vh-table .vh-table__row.disabledClass .vh-checkbox__inner {
      background-color: #bfbfbf;
      border-color: #bfbfbf;
    }
    .vh-table::before {
      height: 0;
    }
  }
</style>
